<template>
  <div>
    <common-header/>
    正在建设中...
    <common-footer/>
  </div>
</template>

<script>
import CommonHeader from '@components/CommonHeader.vue';
import CommonFooter from '@components/CommonFooter.vue';

export default {
  name: 'Index',
  components: {
    CommonHeader,
    CommonFooter,
  },
};
</script>

<style scoped>

</style>
